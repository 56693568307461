/** @format */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto, Helvetica, Arial, sans-serif, monospace";
}

.slick-arrow-icon-left,
.slick-arrow-icon-right {
  position: absolute;
  display: block;
  cursor: pointer;
  background: #184880;
  color: white;
  border-radius: 50px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  transition: 0.5s ease-in-out;
  &:hover,
  &:focus {
    outline: none;
    opacity: 0.8;
    color: white;
    &::before {
      opacity: 1;
    }
  }
}

.slick-arrow-icon-left {
  left: -50px;
  [dir="rtl"] & {
    left: auto;
    right: -50px;
  }
}

.slick-arrow-icon-right {
  right: -50px;
  [dir="rtl"] & {
    left: -50px;
    right: auto;
  }
}
