/** @format */

.sideContent {
  color: #fff;
}
.divider {
  max-height: 35px;
  color: orangered;
  width: 5px;
  font-size: 5px;
  border: 1px solid orangered;
  border-radius: 2px;
  background-color: orangered;
  font-weight: 600px;
}
.title {
  font-size: 25px;
  /* margin-top: 8px; */
}
.sideTitle {
  font-size: 50px;
  color: #fff;
  font-weight: 600px;

  letter-spacing: 0.2rem;
  font-family: "Roboto, Helvetica, Arial, sans-serif";
}

.circle {
  width: 75px;
  height: 75px;
  border-radius: 12px;
  color: #fdfefe;
  font-weight: 600;
  /* margin: auto; */
  margin-top: 1rem;
  margin-left: 1rem;
  transition: all 1s;
}

.ripple {
  box-sizing: border-box;
  position: relative;
}

.ripple:before {
  animation: ripple 2s ease-in infinite;
  border: solid 2px #bb007d;
  border-radius: 10%;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ripple:after {
  animation: ripple 2s 1s ease-in infinite;
  border: solid 2px #bb007d;
  border-radius: 10%;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.facilitiesMain:hover .facilitiesButton {
  color: #196838;
}

@media (max-width: 650px) {
  .navbarResponsive {
    display: flex;
    flex-direction: column;
  }
}

.circular1 {
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular2 {
  position: absolute;

  width: 40px;
  height: 40px;
  border: 0.5px solid white;
  border-radius: 50%;

  animation: wave-animation 2.5s linear infinite;
}
.circular3 {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 0.5px solid white;
  border-radius: 50%;

  animation: wave-animation 3.5s linear infinite;
}
.circular4 {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 0.5px solid white;
  border-radius: 50%;
  animation: wave-animation 3.5s linear infinite;
}

.circular1:hover .circular2 {
  animation-play-state: paused;
}
.circular1:hover .circular3 {
  animation-play-state: paused;
}
.circular1:hover .circular4 {
  animation-play-state: paused;
}

@keyframes wave-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

/* for print css */
@media print {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .printContainer {
    padding-left: 20px !important;
    padding-right: 10px !important;
  }

  .printContainer p {
    page-break-inside: avoid !important;
    /* font-size: 18px !important; */
  }

  .no-print {
    display: none !important;
  }
}
